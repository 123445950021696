<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 text-capitalize">
        <h5 class="mb-0">
          {{ roleOptions === 'admin' ? 'New Administrator' : roleOptions === 'staff' ? 'Create ' + roleOptions + ' member' : roleOptions === 'dataSpecialist' ? 'New Data Specialist' : 'New ' + roleOptions }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
        <!-- Form -->
      <validation-observer ref="simpleRules">
        <b-form
          class="p-2"
        >
          <h5>Details</h5>
          <hr class="mt-0">
          <!-- First Name -->
          <b-form-group
            label-for="first-name"
          >
          <template v-slot:label>
              First Name <span class="text-danger">*</span>
          </template>
            <validation-provider
              #default="{ errors }"
              name="First Name"
              rules="required"
            >
              <b-form-input
                id="first-name"
                v-model="firstName"
                trim
                :state="errors.length > 0 ? false:null"
                placeholder="First Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          
          <!-- Last Name -->
          <b-form-group
            label-for="last-name"
          >
          <template v-slot:label>
              Last Name <span class="text-danger">*</span>
          </template>
            <validation-provider
              #default="{ errors }"
              name="Last Name"
              rules="required"
            >
              <b-form-input
                id="last-name"
                v-model="lastName"
                trim
                :state="errors.length > 0 ? false:null"
                placeholder="Last Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Email -->
          <b-form-group
            label-for="email"
          >
          <template v-slot:label>
              Email Address <span class="text-danger">*</span>
          </template>
            <validation-provider
              #default="{ errors }"
              name="Email Address"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="email"
                :state="errors.length > 0 ? false:null"
                placeholder="Email Address"
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Phone"
            label-for="phone"
            v-if="roleOptions !== 'staff'"
          >
            <b-form-input
                id="phone"
                v-model="contact"
                trim
                placeholder="Phone"
            />
          </b-form-group>

          <b-form-group 
            label="Permissions"
            label-for="permissions"
            v-if="roleOptions === 'staff'"
          >
            <b-form-checkbox
              v-for="(option, index) in permissionsOptions"
              :key="index"
              v-model="selectedPermissions"
              :value="option"
              class="text-capitalize"
              :disabled="selectedPermissions.includes('clinician viewer') && option === 'manage users' ? true : selectedPermissions.includes('clinician viewer') && option === 'data specialist' ? true : selectedPermissions.includes('manage users') && option === 'clinician viewer' ? true : false"
              @change="onCheckboxChange"
            >
              {{ option }}
            </b-form-checkbox>
            <!-- {{selectedPermissions}} -->
            <!-- <b-form-checkbox-group
              v-model="selectedPermissions"
              :options="permissionsOptions"
              class="text-capitalize"
            /> -->
          </b-form-group>

          <!-- <b-form-group
            label="Permissions"
            label-for="permissions"
            v-if="roleOptions === 'staff'"
          >
            <v-select
              v-model="selectedPermissions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :options="permissionsOptions"
              placeholder="Permissions"
              class="text-capitalize"
            />
          </b-form-group> -->

          <h5 class="mt-2">Password</h5>
          <hr class="mt-0">
          <!-- Password -->
          <b-form-group
            label-for="password"
            v-if="roleOptions === 'admin' || roleOptions === 'staff'"
          >
          <template v-slot:label>
              Password <span class="text-danger" v-if="roleOptions === 'admin' || roleOptions === 'staff'">*</span>
          </template>
            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="Password"
              rules="required|password"
            >
              <b-form-input
                v-model="password"
                type="password"
                placeholder="Password"
                :state="errors.length > 0 ? false:null"
                required
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Confirm Password -->
          <b-form-group
            label-for="password confirm"
            v-if="roleOptions === 'admin' || roleOptions === 'staff'"
          >
          <template v-slot:label>
              Confirm Password <span class="text-danger" v-if="roleOptions === 'admin' || roleOptions === 'staff'">*</span>
          </template>
            <validation-provider
              #default="{ errors }"
              name="Confirm Password"
              rules="required|confirmed:Password"
            >
              <b-form-input
                v-model="passwordConfirm"
                :state="errors.length > 0 ? false:null"
                type="password"
                placeholder="Confirm Password"
                required
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click.prevent="validationForm"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox, BFormCheckboxGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, email, password, confirmed,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { mapActions } from "vuex"

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // validation
      required,
      password,
      confirmed,
      email,

      resetForm: true,
      countries,
      fullName: '',
      firstName: '',
      lastName: '',
      email: '',
      contact: '',
      password: '',
      passwordConfirm: '',
      selectedPermissions: [],
      permissionsOptions: ['clinician viewer','manage users','data specialist'],
      // rules: {
      //   required: v => !!v || 'Required.',
      //   min: v => v.length >= 8 || 'Min 8 characters',
      //   email: v => {
      //       const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      //       return pattern.test(v) || 'Email not valid.'
      //   },
      // },
    }
  },
  // validations: {
  //   fullName: {required},
  //   email: {required,email},
  // },
  watch: {
    isAddNewUserSidebarActive(val) {
      // console.log(val)
      this.$refs.simpleRules.reset()
    }
  },
  methods: {
    ...mapActions(['agregarUsuarios']),
    onCheckboxChange(checkedValue) {
      // console.log(checkedValue);
      if(checkedValue.includes('clinician viewer')) {
        this.selectedPermissions = ['clinician viewer']
      }
    },
    submitUsuario(payload) {
      this.agregarUsuarios(payload)
      this.isAddNewUserSidebarActive = false
      this.fullName = ''
      this.firstName = ''
      this.lastName = ''
      this.email = ''
      this.contact = ''
      this.password = ''
      this.passwordConfirm = ''
      this.selectedPermissions = []
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // console.log({nombre: this.firstName + ' ' + this.lastName, firstName: this.firstName, lastName: this.lastName, email: this.email, contact: this.contact, password: this.password, role: this.roleOptions, permissions: this.selectedPermissions})
          this.submitUsuario({nombre: this.firstName + ' ' + this.lastName, firstName: this.firstName, lastName: this.lastName, email: this.email, contact: this.contact, password: this.password, role: this.roleOptions, permissions: this.selectedPermissions})
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
