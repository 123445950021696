var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isAddNewUserSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-add-new-user-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 text-capitalize"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.roleOptions === 'admin' ? 'New Administrator' : _vm.roleOptions === 'staff' ? 'Create ' + _vm.roleOptions + ' member' : _vm.roleOptions === 'dataSpecialist' ? 'New Data Specialist' : 'New ' + _vm.roleOptions)+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"p-2"},[_c('h5',[_vm._v("Details")]),_c('hr',{staticClass:"mt-0"}),_c('b-form-group',{attrs:{"label-for":"first-name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" First Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first-name","trim":"","state":errors.length > 0 ? false:null,"placeholder":"First Name"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"last-name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Last Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last-name","trim":"","state":errors.length > 0 ? false:null,"placeholder":"Last Name"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"email"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email Address "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Email Address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"placeholder":"Email Address","trim":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.roleOptions !== 'staff')?_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('b-form-input',{attrs:{"id":"phone","trim":"","placeholder":"Phone"},model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=$$v},expression:"contact"}})],1):_vm._e(),(_vm.roleOptions === 'staff')?_c('b-form-group',{attrs:{"label":"Permissions","label-for":"permissions"}},_vm._l((_vm.permissionsOptions),function(option,index){return _c('b-form-checkbox',{key:index,staticClass:"text-capitalize",attrs:{"value":option,"disabled":_vm.selectedPermissions.includes('clinician viewer') && option === 'manage users' ? true : _vm.selectedPermissions.includes('clinician viewer') && option === 'data specialist' ? true : _vm.selectedPermissions.includes('manage users') && option === 'clinician viewer' ? true : false},on:{"change":_vm.onCheckboxChange},model:{value:(_vm.selectedPermissions),callback:function ($$v) {_vm.selectedPermissions=$$v},expression:"selectedPermissions"}},[_vm._v(" "+_vm._s(option)+" ")])}),1):_vm._e(),_c('h5',{staticClass:"mt-2"},[_vm._v("Password")]),_c('hr',{staticClass:"mt-0"}),(_vm.roleOptions === 'admin' || _vm.roleOptions === 'staff')?_c('b-form-group',{attrs:{"label-for":"password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Password "),(_vm.roleOptions === 'admin' || _vm.roleOptions === 'staff')?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","placeholder":"Password","state":errors.length > 0 ? false:null,"required":"","trim":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.roleOptions === 'admin' || _vm.roleOptions === 'staff')?_c('b-form-group',{attrs:{"label-for":"password confirm"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Confirm Password "),(_vm.roleOptions === 'admin' || _vm.roleOptions === 'staff')?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"password","placeholder":"Confirm Password","required":"","trim":""},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Add ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }