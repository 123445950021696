<template>
  <div>
    <b-button
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="flat-primary"
        class="mr-50 float-right"
        :to="{ name: 'all-staff-members' }"
    >
        <feather-icon
            icon="EyeIcon"
            class="mr-50"
        />
        <span class="align-middle">Enabled Staff Members</span>
    </b-button>
    <h1 class="mb-1">Disabled Staff Members</h1>
    
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />
    <edit-user :idusuario="idusuario" :show-open.sync="showOpen" />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0 mt-2"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pt-25"
          >
            <h2 class="mb-1">{{totalRows.toLocaleString('en-US')}} <span style="font-size: 14px; color: #000;"> results found</span></h2>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-row class="d-flex align-items-center justify-content-end">
            <!-- <div class="d-flex align-items-center justify-content-end"> -->
              <b-col class="mb-25" md="8">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1 pt-25"
                  placeholder="Search..."
                />
              </b-col>
              <b-col md="4">
                <b-button
                  variant="primary"
                  @click="isAddNewUserSidebarActive = true"
                  v-if="usuario.role === 'admin' || dataSpecialist"
                >
                  <span class="text-nowrap">Add Staff member</span>
                </b-button>
              </b-col>
            <!-- </div> -->
            </b-row>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        :items="usuarios"
        responsive
        :fields="usuario.role === 'admin' ? tableColumnsAdmin : tableColumnsUsers"
        primary-key="id"
        :filter="searchQuery"
        :per-page="perPage"
        :current-page="currentPage"
        show-empty
        empty-text="No records found"
        empty-filtered-text="No results match"
        hover
        @row-clicked="logidusuario"
        v-if="usuario.role === 'admin' || dataSpecialist"
      >
        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="'light-primary'"
                style="background: #d6d6d6;"
              />
            </template>
            <span
              class="font-weight-bold d-block text-nowrap text-primary"
            >
              {{ data.item.fullName }}
            </span>
            <small class="text-muted">{{ data.item.email }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="ToolIcon"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: permissions -->
        <template #cell(permissions)="data">
          <div v-for="(permission, index) in data.item.permissions" :key="index">
            <span class="text-capitalize" v-if="index === 0">{{ permission }}</span>
            <span class="text-capitalize" v-else>, {{ permission }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            @click="deleteStaffMember(data.item.id)"
            v-b-tooltip.hover.top="'Activate'"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
          <!-- <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
            v-if="!data.item.isOwn"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item 
              @click="idusuario = data.item.id, showOpen = true"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown> -->
        </template>
      </b-table>
      <b-table
        ref="refUserListTable"
        :items="usuarios"
        responsive
        :fields="usuario.role === 'admin' ? tableColumnsAdmin : tableColumnsUsers"
        primary-key="id"
        :filter="searchQuery"
        :per-page="perPage"
        :current-page="currentPage"
        show-empty
        empty-text="No records found"
        empty-filtered-text="No results match"
        v-else
      >
        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="'light-primary'"
                style="background: #d6d6d6;"
              />
            </template>
            <span
              class="font-weight-bold d-block text-nowrap text-primary"
            >
              {{ data.item.fullName }}
            </span>
            <small class="text-muted">{{ data.item.email }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="ToolIcon"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: permissions -->
        <template #cell(permissions)="data">
          <div v-for="(permission, index) in data.item.permissions" :key="index">
            <span class="text-capitalize" v-if="index === 0">{{ permission }}</span>
            <span class="text-capitalize" v-else>, {{ permission }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            @click="deleteStaffMember(data.item.id)"
            v-b-tooltip.hover.top="'Activate'"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
          <!-- <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
            v-if="!data.item.isOwn"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item 
              @click="idusuario = data.item.id, showOpen = true"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown> -->
        </template>
      </b-table>
      <b-row class="mb-50">
        <b-col md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <!-- per page -->
          <label class="ml-1">Per Page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="per-page-selector d-inline-block mx-50"
          />
        </b-col>
        <b-col
          cols="6"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="float-right mr-1"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup, BFormSelect,
  BBadge, BDropdown, BDropdownItem, BDropdownItemButton, BPagination, BModal, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import EditUser from '../users-edit/Sidebar/EditUser.vue'
import { mapActions, mapState } from "vuex"
import { db, auth } from '@/firebase'

export default {
  data() {
    return {
        usuarios: [],
        usuarioID: '',
        disabledSubmit: false,
        idusuario: '',
        usuarioSelected: '',
        tableColumnsAdmin: [
            { key: 'name', sortable: false },
            // { key: 'email', sortable: true },
            { key: 'permissions', sortable: false },
            // { key: 'role', sortable: false },
            // { key: 'contact', sortable: true },
            // { key: 'permissions', sortable: false },
            { key: 'actions', label: '', tdClass: 'td-actions-column', thClass: 'th-actions-column' },
        ],
        tableColumnsUsers: [
            { key: 'name', sortable: false },
            { key: 'email', sortable: false },
            { key: 'role', sortable: false },
            { key: 'contact', sortable: false },
        ],
        currentPage: 1,
        perPage: 10,
        pageOptions: [3, 5, 10, 25, 50],
        totalRows: 1,
        boxConfirm: '',

        // user variables
        userData: '',
        clinicianOrClinicianViewer: false,
        clinicianUsers: false,
        dataSpecialist: false,
        adminOrManageUsers: false,
    }
  },
  metaInfo: {
    meta: [
      { name: 'title', content: 'Agentes' }
    ]
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BPagination,
    BFormGroup,
    BFormSelect,

    vSelect,
    BModal,
    EditUser,
  },
  computed: {
    ...mapState(['usuario'])
  },
  watch: {
    statusFilter(val) {
      console.log(val)
    },
    roleFilter(val) {
      console.log(val)
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = 'staff'

    const showOpen = ref(false)

    const statusOptions = [
      { label: 'Invitado', value: 'invitado' },
      { label: 'Activo', value: 'activo' },
      { label: 'Desactivado', value: 'desactivado' },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      showOpen,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    }
  },
  methods: {
    ...mapActions(['eliminarUsuario','habilitarUsuario']),
    logidusuario(item) {
      // console.log(payload)
      this.idusuario = item.id
      this.showOpen = true
    },
    deleteStaffMember(staffId) {
      this.boxConfirm = ''
      this.$bvModal
      .msgBoxConfirm('Are you sure you want to activate this staff member?.', {
        title: 'Activate confirm',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(value => {
        // console.log(value)
        this.boxConfirm = value

        if(value) {
            db.collection('usuarios').doc(staffId).update({
                status: 'active'
            })
            .then(() => {
                this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `Staff member activated successfully.`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                },
                })
            })
        }
      })
    }
  },
  created() {
    const user = auth.currentUser
    if(user) {
      db.collection('usuarios').doc(user.uid).get()
      .then(userDoc => {
        if(userDoc.data().role === 'admin') {
            this.adminOrManageUsers = true
        } else if(userDoc.data().role === 'staff') {
            if(userDoc.data().permissions.includes('manage users')) {
                this.adminOrManageUsers = true
            } else if(userDoc.data().permissions.includes('data specialist')) {
                this.dataSpecialist = true
            } else if(userDoc.data().permissions.includes('clinician viewer')) {
                this.clinicianOrClinicianViewer = true
            } else {
                this.clinicianOrClinicianViewer = true
            }
        } else if(userDoc.data().role === 'clinician') {
            this.clinicianUsers = true
        }
        
        this.userData = userDoc.data()
        // this.loadDataPage = true
      })
    }
    
    const ref = db.collection('usuarios').orderBy("nombre", "desc")
    ref.onSnapshot(querySnapshot => {
      this.usuarios = []
      let ownUser = ''
      querySnapshot.forEach(doc => {
        if(doc.data().role === 'staff') {
            if(!doc.data().status || doc.data().status === 'inactive') {
                // console.log(doc.data())
                ownUser = {
                    id: doc.data().uid,
                    fullName: doc.data().nombre,
                    email: doc.data().email,
                    role: doc.data().role,
                    avatar: doc.data().foto,
                    permissions: doc.data().permissions,
                    contact: doc.data().contact
                }
                this.usuarios.push(ownUser)
            }
        }
      })
      this.totalRows = this.usuarios.length
    })
  }
}
</script>

<style>
.btn-outline-secondary {
  border-radius: 13px !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>