<template>
  <div>
    <b-sidebar
      id="sidebar-right-editar-usuario"
      :visible="showOpen"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      ref="sidebar_editar_usuario"
      @change="(val) => $emit('update:show-open', val)"
    >
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50" style="background-color: #fff;">
            <feather-icon
                class="cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
            />
        </div>
        <div class="b-sidebar-body">
          <div>
            <validation-observer ref="simpleRules">
            <b-form
              class="p-2"
              style="padding-top: 0px !important;"
            >
                <div class="text-center" style="display: grid;">
                    <b-avatar
                    size="70px"
                    variant="light-primary"
                    :src="userData.avatar"
                    style="margin: auto;"
                    :text="avatarText(userData.fullName)"
                    />
                </div>
                <div style="margin-top: 10px; line-height: 30px; font-size: 15px;">
                    <span style="color: #204887; line-height: 23px; font-size: 13px;">Personal information</span>
                    <!-- <b-form-group
                      label="Name"
                      label-for="full-name"
                      style="margin-bottom: 0px !important;"
                    >
                      <b-form-input
                          id="full-name"
                          v-model="userData.fullName"
                          autofocus
                          trim
                          placeholder="John Doe"
                      />
                    </b-form-group> -->
                    <!-- First Name -->
                    <b-form-group
                      label-for="first-name"
                    >
                    <template v-slot:label>
                        First Name <span class="text-danger">*</span>
                    </template>
                      <validation-provider
                        #default="{ errors }"
                        name="First Name"
                        rules="required"
                      >
                        <b-form-input
                          id="first-name"
                          v-model="userData.firstName"
                          trim
                          placeholder="John"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    
                    <!-- Last Name -->
                    <b-form-group
                      label-for="last-name"
                    >
                    <template v-slot:label>
                        Last Name <span class="text-danger">*</span>
                    </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Last Name"
                        rules="required"
                      >
                        <b-form-input
                          id="last-name"
                          v-model="userData.lastName"
                          trim
                          placeholder="Doe"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label-for="correo"
                      style="margin-bottom: 0px !important;"
                    >
                    <template v-slot:label>
                        Email Address<span class="text-danger">*</span>
                    </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <b-form-input
                            id="correo"
                            v-model="userData.email"
                            trim
                            placeholder="Email Address"
                            :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Password -->
                    <b-form-group
                      label-for="password"
                      v-if="userData.role === 'staff'"
                    >
                    <template v-slot:label>
                        Password <span class="text-danger" v-if="userData.role === 'staff'">*</span>
                    </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Password"
                        vid="Password"
                        rules="password"
                      >
                        <b-form-input
                          v-model="password"
                          type="password"
                          placeholder="Password"
                          :state="errors.length > 0 ? false:null"
                          required
                          trim
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Confirm Password -->
                    <b-form-group
                      label-for="password confirm"
                      v-if="userData.role === 'staff'"
                    >
                    <template v-slot:label>
                        Confirm Password <span class="text-danger" v-if="userData.role === 'staff'">*</span>
                    </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Confirm Password"
                        :rules="password ? 'required|confirmed:Password' : 'confirmed:Password'"
                      >
                        <b-form-input
                          v-model="passwordConfirm"
                          :state="errors.length > 0 ? false:null"
                          type="password"
                          placeholder="Confirm Password"
                          required
                          trim
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- <b-form-group
                      label="Phone"
                      label-for="phone"
                      style="margin-bottom: 0px !important;"
                    >
                      <b-form-input
                          id="phone"
                          v-model="userData.contact"
                          trim
                          placeholder="Phone"
                      />
                    </b-form-group> -->
                    <!-- <b-form-group
                      label="Permissions"
                      label-for="permissions"
                      style="margin-bottom: 0px !important;"
                      v-if="userData.role === 'staff'"
                    >
                      <v-select
                        v-model="userData.permissions"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        :options="permissionsOptions"
                        class="text-capitalize"
                      />
                    </b-form-group> -->
                    <b-form-group
                      label="Permissions"
                      label-for="permissions"
                      v-if="userData.role === 'staff'"
                    >
                      <b-form-checkbox
                        v-for="(option, index) in permissionsOptions"
                        :key="index"
                        v-model="userData.permissions"
                        :value="option"
                        class="text-capitalize"
                        :disabled="userData.permissions.includes('clinician viewer') && option === 'manage users' ? true : userData.permissions.includes('clinician viewer') && option === 'data specialist' ? true : userData.permissions.includes('manage users') && option === 'clinician viewer' ? true : false"
                        @change="onCheckboxChange"
                      >
                        {{ option }}
                      </b-form-checkbox>
                    </b-form-group>
                </div>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    class="mt-2"
                    type="submit"
                    style="border-radius: 5px; border-color: #204887 !important; background-color: #204887 !important; float: right;"
                    @click.prevent="validationForm"
                >
                    Save
                </b-button>
            </b-form>
            </validation-observer>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, minLength, password, confirmed, email, } from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { mapActions } from "vuex"
import { db } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpen',
    event: 'update:show-open',
  },
  props: ['idusuario','showOpen'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      permissionsOptions: ['clinician viewer','manage users','data specialist'],
    }
  },
  watch: {
    idusuario(val) {
      if(val) {
        this.userData = {}
        const ref = db.collection('usuarios').doc(val)
        ref.onSnapshot(doc => {
          this.usuarios = {}
          this.usuarios = {
              id: doc.data().uid,
              fullName: doc.data().nombre,
              firstName: doc.data().firstName ? doc.data().firstName : '',
              lastName: doc.data().lastName ? doc.data().lastName : '',
              email: doc.data().email,
              role: doc.data().role,
              avatar: doc.data().foto,
              contact: doc.data().contact ? doc.data().contact : '',
              permissions: doc.data().permissions ?  doc.data().permissions : [],
          }
          this.emailOld = doc.data().email
          this.userData = this.usuarios
        })
      }
    },
    // userData(val) {
    //   console.log(val);
    //   if(val.permissions.includes('clinician viewer')) {
    //     this.selectedPermissions = ['clinician viewer']
    //   }
    // }
  },
  validations: {
  },
  methods: {
    ...mapActions(['editarUsuario']),
    onCheckboxChange(checkedValue) {
      // console.log(checkedValue);
      if(checkedValue.includes('clinician viewer')) {
        this.userData.permissions = ['clinician viewer']
      }
    },
    submitEditar(payload) {
      if(this.password && this.passwordConfirm) {
        let dataBody = JSON.stringify({
          "id": this.userData.id,
          "pass": this.password
        });
        var config = {
          'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/auth/update/usuarios/data',
          'method': 'POST',
          'timeout': 0,
          'headers': {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          'data': dataBody
        }

        this.axios(config)
        .then(res => {
          if(res.status === 200) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Password changed.`,
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(e => console.log(e.message))

        // this.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/auth/update/usuarios/data/${this.userData.id}/${this.password}`)
        // .then(res => { 
        //     // console.log(res.data, res.status)
        //     if(res.status === 200) {
        //       this.$toast({
        //         component: ToastificationContent,
        //         position: 'top-right',
        //         props: {
        //             title: `Password changed.`,
        //             icon: 'CoffeeIcon',
        //             variant: 'success',
        //         },
        //       })
        //     }
        // })
      }

      if(this.userData.email !== this.emailOld) {
        this.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/auth/update/usuarios/email/data/${this.userData.id}/${this.userData.email}`)
        .then(res => { 
            // console.log(res.data, res.status)
            if(res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `Email changed.`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                },
              })
            }
        })
      }
      
      this.editarUsuario(payload)
      this.$refs.sidebar_editar_usuario.hide();
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // console.log({nombre: this.firstName + ' ' + this.lastName, firstName: this.firstName, lastName: this.lastName, email: this.email, contact: this.contact, password: this.password, role: this.roleOptions, permissions: this.selectedPermissions})
          this.submitEditar({id: this.userData.id, fullName: this.userData.fullName, firstName: this.userData.firstName, lastName: this.userData.lastName, email: this.userData.email, contact: this.userData.contact, permissions: this.userData.permissions})
        }
      })
    },
  },
  setup() {
      return {
        avatarText,
      }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.b-sidebar-backdrop {
    background-color: #fefff9 !important;
}
// label {
//   margin-bottom: 0px !important;
//   height: 25px;
// }
// .col-form-label {
//   margin-bottom: 0px !important;
//   height: 25px;
//   font-size: 0.857rem !important;
// }
// .form-control {
//   height: 35px;
// }
// .v-select {
//   height: 35px;
//   margin-top: 0px;
// }
// .vs__dropdown-toggle {
//   height: 35px;
// }
// .vs__selected {
//   margin-top: 0px !important;
// }
</style>