var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{ref:"sidebar_editar_usuario",attrs:{"id":"sidebar-right-editar-usuario","visible":_vm.showOpen,"bg-variant":"white","right":"","backdrop":"","shadow":"","no-header":""},on:{"change":function (val) { return _vm.$emit('update:show-open', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50",staticStyle:{"background-color":"#fff"}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('div',{staticClass:"b-sidebar-body"},[_c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"p-2",staticStyle:{"padding-top":"0px !important"}},[_c('div',{staticClass:"text-center",staticStyle:{"display":"grid"}},[_c('b-avatar',{staticStyle:{"margin":"auto"},attrs:{"size":"70px","variant":"light-primary","src":_vm.userData.avatar,"text":_vm.avatarText(_vm.userData.fullName)}})],1),_c('div',{staticStyle:{"margin-top":"10px","line-height":"30px","font-size":"15px"}},[_c('span',{staticStyle:{"color":"#204887","line-height":"23px","font-size":"13px"}},[_vm._v("Personal information")]),_c('b-form-group',{attrs:{"label-for":"first-name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" First Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first-name","trim":"","placeholder":"John","state":errors.length > 0 ? false:null},model:{value:(_vm.userData.firstName),callback:function ($$v) {_vm.$set(_vm.userData, "firstName", $$v)},expression:"userData.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"last-name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Last Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last-name","trim":"","placeholder":"Doe","state":errors.length > 0 ? false:null},model:{value:(_vm.userData.lastName),callback:function ($$v) {_vm.$set(_vm.userData, "lastName", $$v)},expression:"userData.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticStyle:{"margin-bottom":"0px !important"},attrs:{"label-for":"correo"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email Address"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"correo","trim":"","placeholder":"Email Address","state":errors.length > 0 ? false:null},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.userData.role === 'staff')?_c('b-form-group',{attrs:{"label-for":"password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Password "),(_vm.userData.role === 'staff')?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","placeholder":"Password","state":errors.length > 0 ? false:null,"required":"","trim":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.userData.role === 'staff')?_c('b-form-group',{attrs:{"label-for":"password confirm"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Confirm Password "),(_vm.userData.role === 'staff')?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":_vm.password ? 'required|confirmed:Password' : 'confirmed:Password'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"password","placeholder":"Confirm Password","required":"","trim":""},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.userData.role === 'staff')?_c('b-form-group',{attrs:{"label":"Permissions","label-for":"permissions"}},_vm._l((_vm.permissionsOptions),function(option,index){return _c('b-form-checkbox',{key:index,staticClass:"text-capitalize",attrs:{"value":option,"disabled":_vm.userData.permissions.includes('clinician viewer') && option === 'manage users' ? true : _vm.userData.permissions.includes('clinician viewer') && option === 'data specialist' ? true : _vm.userData.permissions.includes('manage users') && option === 'clinician viewer' ? true : false},on:{"change":_vm.onCheckboxChange},model:{value:(_vm.userData.permissions),callback:function ($$v) {_vm.$set(_vm.userData, "permissions", $$v)},expression:"userData.permissions"}},[_vm._v(" "+_vm._s(option)+" ")])}),1):_vm._e()],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",staticStyle:{"border-radius":"5px","border-color":"#204887 !important","background-color":"#204887 !important","float":"right"},attrs:{"variant":"warning","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Save ")])],1)],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }